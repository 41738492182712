<template>
  <ul class="accordion">
    <li v-for="(item, ix) of data" :key="ix" :class="{ active: activeIndex === ix }">
      <slot
        name="head"
        v-bind="{ title: item.title, tag: item.tag, onClick: () => toggleActive(ix) }"
      >
        <div class="accordion-head" @click="toggleActive(ix)">
          <h4>{{ item.title }}</h4>

          <i class="fa fa-angle-down"></i>
        </div>
      </slot>
      <div class="accordion-body">
        <div class="accordion-body-content">
          <div v-for="(itb, ib) of item.body" :key="ib">
            <p v-if="itb.type === 'p'" :class="itb.class">{{ itb.text }}</p>
            <p v-else-if="itb.type === `b`"><b>{{ itb.text }}</b></p>
            <a v-else-if="itb.type === 'a'" :href=itb.link>{{ itb.text }}  </a>
            <div v-else-if="itb.type === 'ul'" >
              <ul v-for="(list_item, i) of itb.list" :key="i">
                <li v-if="!list_item.title">{{ list_item }}</li>
                <li v-else-if="list_item.title"><strong>{{ list_item.title }} </strong><br>{{ list_item.text }}</li>
              </ul>  
            </div>
            <div v-else-if="itb.type === 'ol'" >
                <ol>
                  <li v-for="(list_item, i) of itb.list" :key="i" >{{ list_item }}</li>
                </ol>
            </div>
          </div>
        </div>
        <div class="accordion-footer">
          <div  v-for="(list_item, i) of item.footer" :key="i" >
            <div v-if="list_item.type === 'p'" >
              <p :class="list_item.class"> {{ list_item.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    activeIndex: null,
  }),
  methods: {
    toggleActive(index) {
      if (this.activeIndex === index) this.activeIndex = null;
      else this.activeIndex = index;
    },
  },
};
</script>

<style></style>
