<template>
  <PageHeader image="header-1" :title="$lang('title')" :breadcrumbs="$lang('breadcr')" withMobile />
  <!-- section1 -->
  <section class="py-8 bg-blue4">
    <div class="container">
      <h2 class="fw-bold text-blue2 mb-3">
        {{ $lang("section1.title1") }}
      </h2>
      <h3 class="fw-semibold text-black2 mb-5">
        {{ $lang("section1.subtitle1") }}
      </h3>
      <h2 class="fw-bold text-green1 mb-3">
        {{ $lang("section1.title2") }}
      </h2>
      <p class="text-black2 mb-6">{{ $lang('section1.text1') }} <strong> {{ $lang('section1.text2') }}</strong> {{ $lang('section1.text3') }} <strong> {{ $lang('section1.text4') }}</strong> {{ $lang('section1.text5') }} <strong> {{ $lang('section1.text6') }}</strong></p>

      <div class="row">
        <div class="col-md-6 col-lg-4 mb-4" v-for="(c, i) of $lang('section1.cards')" :key="i">
          <CardTag
            class="h-100 m-0"
            :tag="c.tag"
            :title="c.title"
            :color="c.color"
            :button="c.button"
            buttonLink="contact_us"
            :withHeart="i === 0"
          >
            <div v-if="c.bodyTitle !== ''"><p><b>{{ c.bodyTitle }}</b></p></div>
            <div><p>{{ c.body }}</p></div>
            <ImageContainer
              :fileName="c.image"
              v-if="c.image"
              background
              contain
              size="30px"
              class="mt-3 mb-4"
            />
          </CardTag>
        </div>
      </div>
    </div>
  </section>
  <ImageContainer background size="600px" fileName="section-header-1" />
  <!-- section2 -->
  <section class="py-8 bg-blue2">
    <div class="container">
      <div class="row justify-content-center text-center mb-6">
        <div class="col-lg-9">
          <h2 class="fw-bold text-green3 mb-3">
            {{ $lang("section2.title") }}
          </h2>
          <p>{{ $lang("section2.text1") }}</p>
        </div>
      </div>
      <div class="d-flex justify-content-between mb-2 mx-4">
        <h5 class="fw-bold text-green3">{{ $lang("section2.text2") }}</h5>
        <h5 class="fw-bold text-green3 text-right">
          {{ $lang("section2.text3") }}
        </h5>
      </div>

      <Accordion :data="$lang('section2.accordion_data')" class="mb-5">
        <template #head="{ title, tag, onClick }">
          <div class="accordion-head accordion-head-tag" @click="onClick">
            <h4>{{ title }}</h4>

            <div class="tag">
              {{ tag }}
              <i class="fa fa-plus"></i>
            </div>
          </div>
        </template>
      </Accordion>

      <div class="text-center">
        <button class="btn btn-green-grad px-5" @click="goTo('contact_us')">
          {{ $lang("section2.button") }}
        </button>
      </div>
    </div>
  </section>
  <!-- section3 -->
  <section class="pt-8 pb-10 bg-blue3">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 offset-lg-1 order-lg-1 mb-5 mb-lg-0">
          <h2 class="fw-bold text-blue2 mb-4">{{ $lang("section3.title") }}</h2>
          <p class="text-white">{{ $lang("section3.text") }}</p>
        </div>
        <div class="col-lg-6">
          <div class="d-grid grid-2-cols gap-4">
            <div class="card card-white m-0">
              <div class="card-body">
                <ImageContainer background contain size="70px" fileName="logo_cambridge_1" />
              </div>
            </div>
            <div class="card card-white m-0">
              <div class="card-body">
                <ImageContainer background contain size="70px" fileName="logo_tie_1" />
              </div>
            </div>
            <div class="card card-white m-0">
              <div class="card-body h-100">
                <ImageContainer
                  background
                  contain
                  size="50px"
                  class="mt-2"
                  fileName="logo_ielts_1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import CardTag from "@/components/CardTag";
import Accordion from "@/components/Accordion.vue";

export default {
  components: {
    PageHeader,
    CardTag,
    Accordion,
  },
};
</script>

<style></style>
