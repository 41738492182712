<template>
  <div class="card card-tag" :class="`card-${color}`">
    <div class="card-header">
      <div class="card-tag" v-if="tag">
        <h4 class="m-0">{{ tag }}</h4>
      </div>
      <h3>{{ title }}</h3>
    </div>
    <div class="heart-bubble" v-if="withHeart">
      <img src="/imgs/svg/heart_bubble.svg" alt="" />
    </div>
    <div class="card-body">
      <slot />
      <button class="btn btn-blue-grad-2" @click="openTab(buttonLink)">{{ button }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "blue2",
    },
    tag: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    button: {
      type: String,
      default: null,
    },
    buttonLink: {
      type: String,
      default: null,
    },
    withHeart: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
